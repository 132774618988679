/* Google Fonts Roboto */
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);

@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);

* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    margin: 0px;
    padding: 0px;
    font-size: 0.9rem;
    line-height: normal;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important; 
    -moz-osx-font-smoothing: grayscale !important;
}
:root {
    --dominant: #fff;
    --compliment: #fff;
    --ascent: #560bad;

    --ascent-transparent: rgba(86, 11, 173, 0.07);
    --ascent-light: #af6ef8;
}
.alert-ascent {
    color: var(--ascent);
    background-color: var(--ascent-transparent);
    border-color: var(--ascent-transparent);
}
.alert-red {
    color: #d90429;
    background-color: rgba(255, 0, 43, 0.07);
    border-color: rgba(255, 0, 43, 0.02);
}
.alert-ascent:hover {
    color: var(--ascent-transparent);
}
.alert-red:hover {
    color: #d90429;
}
p.alert-ascent:hover {
    color: var(--ascent) !important;
}
hr {
    height: 0.9px !important;
    background-color: #000;
    opacity: 0.1;
    border-radius: 0.5px;
}
.form-check {
    display: flex;
}
.form-check label {
    margin-left: 5px;
    margin-top: 2px;
    user-select: none;
    cursor: pointer;
}
.form-label {
    font-size: 0.9rem;
}
.form-check input {
    cursor: pointer;
}
p.btn {
    margin-bottom: 0px;
}
p.btn:hover {
    cursor: default !important;
}

/* Buttons (Start) */
a.btn:focus, 
a.btn:active,
button.btn:focus, 
button.btn:active,
select:focus, 
select:active  {
    outline: none !important;
    box-shadow: none !important;
}
.btn {
    border-radius: 0.37rem;
    border: 1.9px solid transparent;
    display: inline-flexbox;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0px;
    width: fit-content;
    font-weight: 500;
}
.btn-lg svg {
    margin-top: -6px;
}
a.btn:active,
button.btn:active {
    transform: scale(1.1);
    transition: 0.2s ease;
}
a.btn-light:active,
button.btn-light:active {
    color: #fff !important;
}
a.card-link {
    text-decoration: none !important;
    font-weight: 500;
    color: var(--ascent);
    line-height: 1.5;
    width: fit-content;
}
a.card-link:hover {
    color: #000;
}
a.btn-ascent,
button.btn-ascent {
    color: #fff;
    background-color: var(--ascent);
    border-color: var(--ascent);
}
.btn-ascent.borderd {
    color: var(--ascent);
    background-color: transparent;
}
.btn-ascent.active {
    color: #fff;
    background-color: #000;
    border-color: #000;
}
.btn-ascent.active:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
}
.btn-pink {
    color: #fff;
    background-color: #ff0a54;
    border-color: #ff0a54;
}
.breadcrumb-item a {
    text-decoration: none !important;
    font-weight: normal;
    color: #222;
}
@media (min-width: 767px) {
    a.btn:hover,
    button.btn:hover {
        background-color: #000;
        border-color: #000;
        color: #fff;
    }
}
@media (max-width: 767px) {
    a.btn:hover,
    button.btn:hover {
        color: #fff;
    }
    a.btn-light:hover,
    button.btn-light:hover {
        color: #000 !important;
    }
    a.btn-light:active,
    button.btn-light:active {
        color: #000 !important;
    }
}
/* Buttons (End) */

/* Input Boxes (Start) */
.input-box {
    background-color: transparent;
    border-radius: 0.37rem;
    color: #000;
    outline: none;
    text-align: left;
    width: 100%;
}
.form-check-input:checked {
    background-color: var(--ascent) !important;
    border-color: var(--ascent) !important;
}
/* Input Boxes (End) */

/* Sections (Start) */
section {
    height: auto;
    width: 100%;
    overflow: hidden;
    color: #010101;
}
section.section {
    background-color: var(--dominant);
    padding: 80px 10px;
    border-bottom: 1px solid #eee;
    position: relative;
    z-index: 5;
}
@media (max-width: 767px) {
    section.section {
        padding: 30px 10px;
    }
}
div.section-btn {
    text-align: left;
    padding: 0px 10px;
    padding-top: 30px;
}
div.section-title {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 50px;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
div.section-title h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 3px;
}
div.section-title p {
    margin-bottom: 0px;
    font-size: 0.9rem;
}
div.banner-card-div {
    padding: 10px;
}
div.banner-card {
    height: auto;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
}
div.banner-card div.card-body {
    padding: 70px !important;
    color: #fff;
    background-color: transparent !important;
}
div.banner-card div.card-body h6 {
    font-size: 0.9rem;
    font-weight: 300;
    text-transform: uppercase;
}
div.banner-card div.card-body h3 {
    font-size: 1.7rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 7px;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    position: relative;
    width: fit-content;
}
div.banner-card div.card-body h3::after {
    content: " ";
    position: absolute;
    height: 3px;
    width: 30%;
    background-color: #fff;
    border-radius: 3px;
    top: 100%;
    left: 0%;
}
div.banner-card div.card-body p {
    font-size: 0.8rem;
    font-weight: normal;
}
@media (max-width: 767px) {
    div.section-title {
        margin-bottom: 20px;
        padding: 0px 0px;
    }
    main section:first-child {
        margin-top: 130px;
    }
    div.section-btn {
        padding: 10px 0px;
    }
    div.banner-card-div {
        padding: 0px;
    }
    div.banner-card div.card-body {
        padding: 40px !important;
    }
}
/* Sections (End) */

/* Cards (Start) */
.card {
    border-radius: 0.37rem;
}
.card-sm { width: 40%; }
.card-md { width: 50%; }
.card-lg { width: 70%; }
.card-xl { width: 100%; }
.card div.card-body,
.card div.card-header,
.card div.card-footer  {
    padding: 25px;
    background-color: #fff;
}
.card div.card-body {
    border-radius: 0.37rem;
    background-color: transparent;
}
.card div.card-header {
    border-top-left-radius: 0.37rem;
    border-top-right-radius: 0.37rem;
}
.card div.card-footer {
    border-bottom-left-radius: 0.37rem;
    border-bottom-right-radius: 0.37rem;
}
.card div.card-header h4.title {
    font-size: 1.4rem;
    margin-bottom: 0px;
    font-family: 'Poppins',sans-serif;
    font-weight: bold;
    color: #333;
}
.card .title {
    font-family: 'Poppins',sans-serif;
    font-weight: bold;
    color: #333;
}
.card div.card-body h6.title {
    font-family: 'Poppins',sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}
.card div.card-body p.description {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 5px;
}
@media (max-width: 767px) {
    .card-sm { width: 100%; }
    .card-md { width: 100%; }
    .card-lg { width: 100%; }
    .card-xl { width: 100%; }
    .card div.card-body,
    .card div.card-header,
    .card div.card-footer  {
        padding: 15px;
    }
}
/* Cards (End) */

/* Body */
body {
    height: auto;
    width: 100%;
    overflow-x: hidden;
    zoom: 100%;
}
main {
    height: auto;
    width: 100%;
    overflow-x: hidden;
}
@media (max-width: 767px) {
    * {
        font-size: 0.9rem;
    }
    body {
        zoom: 100%;
    }
}