@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
/* Google Fonts Roboto */

* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    margin: 0px;
    padding: 0px;
    font-size: 0.9rem;
    line-height: normal;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important; 
    -moz-osx-font-smoothing: grayscale !important;
}
:root {
    --dominant: #fff;
    --compliment: #fff;
    --ascent: #560bad;

    --ascent-transparent: rgba(86, 11, 173, 0.07);
    --ascent-light: #af6ef8;
}
.alert-ascent {
    color: #560bad;
    color: var(--ascent);
    background-color: rgba(86, 11, 173, 0.07);
    background-color: var(--ascent-transparent);
    border-color: rgba(86, 11, 173, 0.07);
    border-color: var(--ascent-transparent);
}
.alert-red {
    color: #d90429;
    background-color: rgba(255, 0, 43, 0.07);
    border-color: rgba(255, 0, 43, 0.02);
}
.alert-ascent:hover {
    color: rgba(86, 11, 173, 0.07);
    color: var(--ascent-transparent);
}
.alert-red:hover {
    color: #d90429;
}
p.alert-ascent:hover {
    color: #560bad !important;
    color: var(--ascent) !important;
}
hr {
    height: 0.9px !important;
    background-color: #000;
    opacity: 0.1;
    border-radius: 0.5px;
}
.form-check {
    display: flex;
}
.form-check label {
    margin-left: 5px;
    margin-top: 2px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}
.form-label {
    font-size: 0.9rem;
}
.form-check input {
    cursor: pointer;
}
p.btn {
    margin-bottom: 0px;
}
p.btn:hover {
    cursor: default !important;
}

/* Buttons (Start) */
a.btn:focus, 
a.btn:active,
button.btn:focus, 
button.btn:active,
select:focus, 
select:active  {
    outline: none !important;
    box-shadow: none !important;
}
.btn {
    border-radius: 0.37rem;
    border: 1.9px solid transparent;
    display: inline-flexbox;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
}
.btn-lg svg {
    margin-top: -6px;
}
a.btn:active,
button.btn:active {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: 0.2s ease;
}
a.btn-light:active,
button.btn-light:active {
    color: #fff !important;
}
a.card-link {
    text-decoration: none !important;
    font-weight: 500;
    color: #560bad;
    color: var(--ascent);
    line-height: 1.5;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
a.card-link:hover {
    color: #000;
}
a.btn-ascent,
button.btn-ascent {
    color: #fff;
    background-color: #560bad;
    background-color: var(--ascent);
    border-color: #560bad;
    border-color: var(--ascent);
}
.btn-ascent.borderd {
    color: #560bad;
    color: var(--ascent);
    background-color: transparent;
}
.btn-ascent.active {
    color: #fff;
    background-color: #000;
    border-color: #000;
}
.btn-ascent.active:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
}
.btn-pink {
    color: #fff;
    background-color: #ff0a54;
    border-color: #ff0a54;
}
.breadcrumb-item a {
    text-decoration: none !important;
    font-weight: normal;
    color: #222;
}
@media (min-width: 767px) {
    a.btn:hover,
    button.btn:hover {
        background-color: #000;
        border-color: #000;
        color: #fff;
    }
}
@media (max-width: 767px) {
    a.btn:hover,
    button.btn:hover {
        color: #fff;
    }
    a.btn-light:hover,
    button.btn-light:hover {
        color: #000 !important;
    }
    a.btn-light:active,
    button.btn-light:active {
        color: #000 !important;
    }
}
/* Buttons (End) */

/* Input Boxes (Start) */
.input-box {
    background-color: transparent;
    border-radius: 0.37rem;
    color: #000;
    outline: none;
    text-align: left;
    width: 100%;
}
.form-check-input:checked {
    background-color: #560bad !important;
    background-color: var(--ascent) !important;
    border-color: #560bad !important;
    border-color: var(--ascent) !important;
}
/* Input Boxes (End) */

/* Sections (Start) */
section {
    height: auto;
    width: 100%;
    overflow: hidden;
    color: #010101;
}
section.section {
    background-color: #fff;
    background-color: var(--dominant);
    padding: 80px 10px;
    border-bottom: 1px solid #eee;
    position: relative;
    z-index: 5;
}
@media (max-width: 767px) {
    section.section {
        padding: 30px 10px;
    }
}
div.section-btn {
    text-align: left;
    padding: 0px 10px;
    padding-top: 30px;
}
div.section-title {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 50px;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
div.section-title h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 3px;
}
div.section-title p {
    margin-bottom: 0px;
    font-size: 0.9rem;
}
div.banner-card-div {
    padding: 10px;
}
div.banner-card {
    height: auto;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
}
div.banner-card div.card-body {
    padding: 70px !important;
    color: #fff;
    background-color: transparent !important;
}
div.banner-card div.card-body h6 {
    font-size: 0.9rem;
    font-weight: 300;
    text-transform: uppercase;
}
div.banner-card div.card-body h3 {
    font-size: 1.7rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 7px;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
div.banner-card div.card-body h3::after {
    content: " ";
    position: absolute;
    height: 3px;
    width: 30%;
    background-color: #fff;
    border-radius: 3px;
    top: 100%;
    left: 0%;
}
div.banner-card div.card-body p {
    font-size: 0.8rem;
    font-weight: normal;
}
@media (max-width: 767px) {
    div.section-title {
        margin-bottom: 20px;
        padding: 0px 0px;
    }
    main section:first-child {
        margin-top: 130px;
    }
    div.section-btn {
        padding: 10px 0px;
    }
    div.banner-card-div {
        padding: 0px;
    }
    div.banner-card div.card-body {
        padding: 40px !important;
    }
}
/* Sections (End) */

/* Cards (Start) */
.card {
    border-radius: 0.37rem;
}
.card-sm { width: 40%; }
.card-md { width: 50%; }
.card-lg { width: 70%; }
.card-xl { width: 100%; }
.card div.card-body,
.card div.card-header,
.card div.card-footer  {
    padding: 25px;
    background-color: #fff;
}
.card div.card-body {
    border-radius: 0.37rem;
    background-color: transparent;
}
.card div.card-header {
    border-top-left-radius: 0.37rem;
    border-top-right-radius: 0.37rem;
}
.card div.card-footer {
    border-bottom-left-radius: 0.37rem;
    border-bottom-right-radius: 0.37rem;
}
.card div.card-header h4.title {
    font-size: 1.4rem;
    margin-bottom: 0px;
    font-family: 'Poppins',sans-serif;
    font-weight: bold;
    color: #333;
}
.card .title {
    font-family: 'Poppins',sans-serif;
    font-weight: bold;
    color: #333;
}
.card div.card-body h6.title {
    font-family: 'Poppins',sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}
.card div.card-body p.description {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 5px;
}
@media (max-width: 767px) {
    .card-sm { width: 100%; }
    .card-md { width: 100%; }
    .card-lg { width: 100%; }
    .card-xl { width: 100%; }
    .card div.card-body,
    .card div.card-header,
    .card div.card-footer  {
        padding: 15px;
    }
}
/* Cards (End) */

/* Body */
body {
    height: auto;
    width: 100%;
    overflow-x: hidden;
    zoom: 100%;
}
main {
    height: auto;
    width: 100%;
    overflow-x: hidden;
}
@media (max-width: 767px) {
    * {
        font-size: 0.9rem;
    }
    body {
        zoom: 100%;
    }
}
div.product-card-div {
    padding: 10px;
}
div.product-card-div a {
    text-decoration: none;
    color: #000;
}
figure.product-card {
    position: relative;
    margin: 0px;
    border-radius: 10px;
    transition: 0.2s ease;
    background: inherit;
    z-index: 2;
}
figure.product-card div.card-body {
    border-radius: 11px;
}
figure.product-card:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.2s ease;
    background: inherit;
}
figure.product-card:hover h6 {
    color: var(--ascent);
}
figure.product-card img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
figure.product-card h6 {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
}
figure.product-card h5.price {
    margin-top: 12px;
    margin-bottom: 0px;
    display: flex;
    justify-content: start;
    align-items: baseline;
}
figure.product-card h5.price span.new {
    font-family: 'Inter', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
}
figure.product-card h5.price span.old {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    text-decoration: line-through;
    color: #666;
    margin-left: 10px;
}
figure.product-card div.rating-stars {
    display: flex;
    justify-content: start;
    align-items: center;
}
figure.product-card div.rating-stars span {
    display: inline-block;
    margin-right: 2px;
}
figure.product-card div.rating-stars span.active {
    color: orange;
}
figure.product-card div.rating-stars span.inactive {
    color: #ccc;
}
figure.product-card div.row {
    display: flex;
}
figure.product-card div.row div {
    position: relative;
}
figure.product-card button.add-to-wishlist {
    text-decoration: none;
    color: #999;
    position: absolute;
    bottom: 5px;
    right: 20px;
    transition: 0.2s ease;
    font-size: 0.8rem;
    border: none;
    background: transparent;
    z-index: 3;
    background: inherit;
}
figure.product-card button.add-to-wishlist svg {
    margin-left: 3px;
    height: 35px;
    width: 35px;
}
figure.product-card button.add-to-wishlist:hover {
    color: #ff0a54;
    transition: 0.2s ease;
}

@media (max-width: 767px) {
    div.product-card-div {
        padding: 10px 0px;
    }
    figure.product-card h6 {
        font-size: 0.8rem;
    }
    figure.product-card div.card-body {
        padding: 12px 10px;
    }
    figure.product-card h5.price span.new {
        font-size: 0.95rem;
    }
    figure.product-card h5.price span.old {
        font-size: 0.8rem;
        margin-left: 5px;
    }
    figure.product-card button.add-to-wishlist {
        bottom: 0px;
        right: 13px;
    }
    figure.product-card button.add-to-wishlist svg {
        margin-left: 0px;
        height: 23px;
        width: 23px;
    }
}
div.product-carousel {
    height: 70%;
    width: 100%;
    float: right;
    margin-right: 20px;
}
div.product-carousel img {
    outline: none !important;
}
@media (max-width: 767px) {
    div.product-carousel {
        margin: 0px;
        float: none;
        margin-bottom: 20px;
        width: 100%;
    }
}
div.product-carousel-preview {
    padding-right: 3px;
    border: none;
    margin: 1px;
    margin-bottom: 3px;
    border-radius: 5px;
}
div.product-preview-div {
    padding: 50px;
    overflow: hidden;
}
div.product-preview-div:hover img {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}
div.product-thumbnail-div {
    padding: 10px;
}
div.product-carousel-thumbnaliNav {
    padding-right: 5px;
}
div.product-carousel-thumbnaliNav img.product-carousel-thumbnail {
    cursor: pointer;
    border: 1.7px solid #ccc;
    opacity: 0.5;
    border-radius: 3px;
    outline: none;
}
div.product-carousel-thumbnaliNav .slick-current img {
    opacity: 1;
    cursor: default;
    outline: none;
}

article.content figure {
    margin-bottom: 25px;
    padding: 0px;
}
article.content figure * {
    margin-bottom: 0px;
}
article.content figure h6 {
    color: #222;
    font-size: 1rem;
}
article.content figure h6 a {
    color: #222;
    font-size: 1rem;
}
h3.product-name {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}
div.rating-stars {
    display: flex;
    justify-content: baseline;
}
div.rating-stars span.active {
    color: orange;
}
div.rating-stars span.inactive {
    color: #ccc;
}

h6.title {
    font-weight: 600;
    color: #333;
    font-family: 'Poppins',sans-serif;
}
figure.pricing {
    margin: 10px 0px;
    display: flex;
    justify-content: start;
    align-items: baseline;
}
figure.pricing h2,
figure.pricing h5 {
    margin-right: 15px;
    font-family: 'Poppins', sans-serif;
}
figure.pricing h2.discounted-price {
    font-weight: bold;
    color: #000;
}
figure.pricing h5.actual-price {
    font-weight: bold;
    color: #999;
    text-decoration: line-through;
}
figure.pricing h5.discount-percentage {
    color: green;
    font-weight: bold;
}
figure.category a {
    margin-right: 5px;
    font-size: 1.1rem;
}
figure.highlight ul.highlight-list li {
    font-size: 0.9rem;
    margin-bottom: 7px;
}
figure.highlight ul.highlight-list {
    padding-left: 17px;
    padding-top: 20px;
    padding-bottom: 5px;
}
figure.size ul.size-list li {
    display: inline-block;
    margin-right: 7px;
    margin-top: 10px;
}
figure.size ul.size-list li button.size-tab {
    height: 40px;
    min-width: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border: none;
    border-radius: 7px;
    background-color: var(--ascent-transparent);
    color: #000;
}
figure.size ul.size-list li button.size-tab:focus {
    background-color: #000;
    color: #fff;
}
figure.size ul.size-list li button.size-tab.active {
    background-color: #000;
    color: #fff;
}
figure.tags a.tag {
    margin-right: 7px;
    font-size: 0.9rem;
    border: 1.5px solid var(--ascent);
    padding: 5px 10px;
    color: var(--ascent);
    border-radius: 2em;
    text-decoration: none !important;
    font-weight: 500;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
    margin-top: 7px;
}
/* Dashboard Tabs (Start) */
aside.dashboard-tabs {
    overflow-x: scroll;
}
aside.dashboard-tabs::scrollbar {
    display: none;
}
aside.dashboard-tabs::-webkit-scrollbar {
    display: none;
}
aside.dashboard-tabs ul.dashboard-tab-list {
    display: flex;
    flex-direction: column;
}
aside.dashboard-tabs ul.dashboard-tab-list li {
    white-space: nowrap;
}
aside.dashboard-tabs a.dashboard-tab {
    padding: 10px 15px;
    color: #777;
    text-decoration: none;
    line-height: 1.5;
    background-color: transparent;
    font-weight: 500;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    border-radius: 0.37rem;
    border: none;
    margin-bottom: 5px;
    position: relative;
}
aside.dashboard-tabs a.dashboard-tab.active {
    color: var(--ascent);
    background-color: var(--ascent-transparent);
}
aside.dashboard-tabs a.dashboard-tab::after {
    content: " ";
    position: absolute;
    height: 0%;
    width: 5px;
    background-color: var(--ascent);
    top: 50%;
    left: 0%;
    -webkit-transform: translate(-0%,-50%);
            transform: translate(-0%,-50%);
    border-radius: 2px;
    transition: 0.1s ease;
}
aside.dashboard-tabs a.dashboard-tab.active::after {
    height: 60%;
    transition: 0.3s ease;
}
aside.dashboard-tabs a.dashboard-tab span.tab-icon {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
}
/* Dashboard Tabs (End) */

/* Dashboard Card (Start) */
div.dashboard-card div.card-body,
div.dashboard-card div.card-header,
div.dashboard-card div.card-footer  {
    padding: 25px;
    background-color: #fff;
}
div.dashboard-card div.card-header h4.title {
    font-size: 1.4rem;
    margin-bottom: 0px;
    font-family: 'Poppins',sans-serif;
    font-weight: bold;
    color: #333;
}
div.dashboard-card div.card-body div.card {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.125);
}
div.dashboard-card div.card-body div.card:last-child {
    border-bottom: none;
    border-bottom-left-radius: 5px !important;
}
div.dashboard-card div.card-body div.card div.card-body {
    padding: 25px;
    border-radius: 5px;
}
div.dashboard-card div.card-body h6.title {
    font-family: 'Poppins',sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}
div.dashboard-card div.card-body p.description {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 5px;
}
/* Dashboard Card (End) */

/* My Orders (Start) */
div.order-card {
    border-radius: 0px !important;
}
div.order-card div.actions {
    margin-top: 15px;
}
div.order-item-card div.card-body {
    padding-top: 0px !important;
    display: flex;
    justify-content: start;
}
div.order-item-card div.product-details {
    margin-left: 10px;
    padding-top: 10px;
}
div.order-item-card img {
    height: 100px;
    width: 100px;
}
/* My Orders (End) */

/* Media Queries */
@media (max-width: 767px) {
    aside.dashboard-tabs ul.dashboard-tab-list {
        display: flex;
        flex-direction: row;
    }
    div.dashboard-card div.card-body,
    div.dashboard-card div.card-header,
    div.dashboard-card div.card-footer {
        padding: 15px;
    }
    div.dashboard-card div.card-body div.card div.card-body {
        padding: 15px;
    }
    div.dashboard-card div.card-body h6.title {
        font-size: 1rem;
    }
    aside.dashboard-tabs a.dashboard-tab {
        padding: 10px 20px;
    }
    aside.dashboard-tabs a.dashboard-tab::after {
        content: none;
    }
    aside.dashboard-tabs a.dashboard-tab.active::after {
        content: none;
    }
}


figure.filter-card div.card-header,
figure.filter-card div.card-body,
figure.filter-card div.card-footer {
    padding: 15px !important;
}
figure.filter-card div.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
figure.filter-card h6.filter-title {
    font-family: 'Inter', sans-serif !important;
    margin-bottom: 0px !important;
    font-weight: 500;
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
    color: var(--ascent);
}
figure.filter-card div.form-check label.form-check-label {
    font-size: 1rem;
    margin-left: 7px;
    margin-top: 3px;
}
figure.filter-card div.form-check input.form-check-input {
    height: 1.25em;
    width: 1.25em;
}
figure.filter-card.active div.card-body  {
    display: block;
    transition: 0.3s ease;
}
figure.filter-card.inactive div.card-body {
    display: none;
    opacity: 0;
    transition: 0.3s ease;
}
figure.filter-card.inactive div.card-header {
    border-bottom: none !important;
    border-radius: 0.37rem;
    transition: all .1s ease;
}
figure.filter-card span.filter-card-toggler {
    color: var(--ascent);
}
figure.filter-card.active span.filter-card-toggler  {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: 0.3s ease;
}
figure.filter-card.inactive span.filter-card-toggler{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: 0.3s ease;
}
div.cart-items figure.product-card-cart {
    margin: 0px;
    margin-bottom: 25px;
}
div.cart-items figure.product-card-cart div.card-body,
div.cart-items figure.product-card-cart div.card-footer {
    display: flex;
    padding: 15px;
    background-color: #fff;
}
div.cart-items figure.product-card-cart div.card-footer button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
div.cart-items figure.product-card-cart div.card-body div.details {
    padding: 15px;
}
figure.product-card-cart div.card-body img.product-img {
    height: 150px;
    width: 150px;
    border-radius: 5px;
    padding: 5px;
    margin: 2px;
}
figure.product-card-cart div.card-body h6.product-name {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-decoration: none;
    color: #000;
    font-weight: 500;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}
figure.product-card-cart div.card-body h6.product-price-new {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-top: 10px;
    text-decoration: none;
    color: #000;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 0px;
}
figure.product-card-cart div.card-body h6.product-price-old {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    margin-top: 10px;
    text-decoration: none;
    color: #666;
    font-weight: normal;
    text-decoration: line-through;
}
figure.product-card-cart div.card-body p.description {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 5px;
}
figure.product-card-cart div.card-body div.size-button-div button.size-button {
    height: 28px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 0.8rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 0.37rem;
    background-color: var(--ascent-transparent);
    margin-right: 4px;
}
figure.product-card-cart div.card-body div.size-button-div button.size-button.active {
    background-color: var(--ascent);
    color: #fff;
}
figure.product-card-cart div.card-body div.quantity-button-div button.quantity-button {
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    border: 1px solid var(--ascent);
    border-radius: 0.37rem;
    background-color: var(--ascent);
    color: #fff;
    margin: 0px !important;
    line-height: 1;
    font-weight: bold;
}
figure.product-card-cart div.card-body div.quantity-button-div input {
    height: 28px;
    width: 40px;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 1rem;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    border: 1px solid #ccc;
    align-items: center;
    margin: 0px 5px;
    cursor: default;
}
table.order-summary td {
    padding: 13px 0px;
}
table.order-summary h6.heading {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0px;
}
table.order-summary h6.data {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #777;
    font-size: 1.1rem;
    margin-bottom: 0px;
}
table.order-summary h6.heading.total {
    font-size: 1.2rem;
}
@media (max-width: 767px) {
    div.cart-items figure.product-card-cart div.card-body,
    div.cart-items figure.product-card-cart div.card-footer {
        display: flex;
        padding: 7px;
        background-color: #fff;
    }
    div.cart-items figure.product-card-cart div.card-footer button {
        width: 50%;
    }
    div.cart-items figure.product-card-cart div.card-body div.details {
        padding: 7px;
        padding-left: 10px;
    }
    figure.product-card-cart div.card-body h6.product-name {
        font-size: 1rem;
        margin-bottom: 5px;
    }
    figure.product-card-cart div.card-body h6.product-price {
        font-size: 1rem;
        margin-bottom: 5px;
    }
    figure.product-card-cart div.card-body img.product-img {
        height: 125px;
        width: 125px;
    }
}

footer.footer {
    background-color: #1b1a1b;
}
footer.footer div.container.top {
    padding: 50px 20px;
}
footer.footer div.footer-card {
    background-color: transparent;
    border: none;
    border-radius: 0px !important;
    border-right: 1px solid #ccc;
}
footer.footer div.footer-card div.card-body {
    padding: 10px;
    display: inline-flex;
}
footer.footer div.footer-card span.footer-card-icon {
    color: var(--ascent-light);
    padding-right: 15px;
}
div.container.top div:last-child > div.footer-card {
    border-right: none !important;
}
footer.footer div.footer-card h6.title {
    color: #fff;
    margin-bottom: 10px;
}
footer.footer div.footer-card p.description {
    color: #eee;
    margin-bottom: 0px !important;
}
footer.footer div.container.bottom {
    padding: 70px 20px;
    padding-bottom: 50px !important;
}
footer.footer div.copyright-div {
    padding: 20px 0px;
    background-color: #000;
    color: #fff;
    text-align: center;
}
footer.footer div.copyright-div p {
    margin-bottom: 0px;
    font-weight: lighter;
    font-size: 0.9rem;
}
footer.footer ul.social-media li {
    display: inline-block;
    margin-right: 20px;
}
footer.footer li.footer-list-item {
    padding: 7px 0px;
}
footer.footer ul.list-group {
    margin-left: 17px;
}
footer.footer ul.list-group li.footer-list-item {
    list-style: circle;
}
footer.footer h6.footer-list-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 16px;
    position: relative;
}
footer.footer h6.footer-list-title:after {
    position: absolute;
    content: " ";
    height: 3px;
    width: 50px;
    background-color: var(--ascent-light);
    left: 0px;
    bottom: 0px;
    border-radius: 1.5px;
}
footer.footer a.footer-link {
    color: #ccc;
    text-decoration: none;
    font-size: 0.9rem;
    margin-left: 0px;
    transition: 0.2s ease;
    font-weight: 400;
}
footer.footer a.footer-link:hover {
    transition: 0.2s ease;
    color: #fff;
}
footer.footer p.description {
    color: #ccc;
    text-decoration: none;
    font-size: 0.9rem;
    line-height: 1.6;
}
footer.footer input {
    color: #fff;
}
@media (max-width: 767px) {
    footer.footer ul {
        margin-bottom: 35px;
    }
    footer.footer div.container.top {
        padding: 30px 20px;
        padding-bottom: 15px;
    }
    footer.footer div.container.bottom {
        padding: 30px 20px;
        padding-bottom: 10px !important;
    }
    footer.footer div.footer-card {
        border-right: none;
        border-bottom: 1px solid #999;
    }
    footer.footer div.footer-card div.card-body {
        padding: 20px 0px;
    }
    div.container.top div:last-child > div.footer-card {
        border-bottom: none !important;
    }
}
/* Header  */
header.header {
    height: auto;
    width: 100%;
    position: static;
    z-index: 10;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    background-color: #fff;
}
#desktop-header {
    display: block;
}
#mobile-header {
    display: none;
    position: fixed;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}
header.header nav {
    margin: 0px;
    padding: 0px;
}
header.header nav#top-nav { 
    padding: 20px 0px;
    background-color: var(--compliment);
}
header.header nav#bottom-nav { 
    padding: 0px 0px;
    background-color: var(--ascent);
}
@media (max-width: 1000px) {
    header.header {
        border-bottom: 1px solid #ccc;
    }
    #desktop-header {
        display: none;
    }
    #mobile-header {
        display: block;
    }
}

/* Dropdown */
.dropdown {
    position: relative;
    z-index: 5;
}
.dropdown-menu {
    position: absolute;
    z-index: 3;
}
.dropdown:hover .dropdown-menu {
    display: block;
}


span.logo {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: normal;
    color: var(--ascent);
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}
li.nav-item {
    position: relative;
    margin: 0px 10px;
}
a.nav-link {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    margin: 0px;
    padding: 0px;
    font-size: 0.9rem;
    font-weight: 500;
}
header.header nav#top-nav a.nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}
header.header nav#top-nav a.nav-link div.nav-link-caption {
    margin-left: 10px;
    text-align: left;
    padding: 0px !important;
    margin-top: -2px;
}
header.header nav#top-nav a.nav-link div.nav-link-caption label {
    font-size: 0.85rem;
    cursor: pointer;
    color: #000;
    font-family: 'Poppins',sans-serif;
    margin-bottom: 1px;
    white-space: nowrap;
}
header.header nav#top-nav a.nav-link div.nav-link-caption hr {
    margin: 1px auto;
    padding: 0px 7px;
}
header.header nav#top-nav a.nav-link div.nav-link-caption .card-link {
    margin: 0px !important;
    font-size: 0.7rem;
    display: inline-block;
    white-space: nowrap;
}
header.header nav#bottom-nav a.nav-link {
    color: #fff;
    padding: 20px 10px;
    position: relative;
}
header.header nav#bottom-nav a.nav-link:hover {
    color: #fff;
}
header.header nav#bottom-nav a.nav-link:not(.ignore):after {
    content: " ";
    position: absolute;
    height: 5px;
    width: 0%;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    background-color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
header.header nav#bottom-nav a.nav-link:hover::after {
    width: 100%;
}
header.header nav#bottom-nav a.nav-link.active::after {
    width: 100%;
}
span.nav-icon {
    margin-top: -2px;
}
span.icon {
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #555;
    border: 1.7px solid #555;
}
span.icon:hover {
    color: #fff;
    border-color: var(--ascent);
    background-color: var(--ascent);
}
span.icon span.icon-badge {
    position: absolute;
    height: 20px;
    width: 20px;
    top: -5px;
    right: -5px;
    border-radius: 10px;
    font-size: 0.6rem;
    background-color: var(--ascent);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}
div.search-box select {
    width: 25%;
}
div.search-box input {
    width: 65%;
}
div.search-box button {
    width: 10%;
}
@media (max-width: 767px) {
    div.search-box select {
        width: 0%;
    }
    div.search-box input {
        width: 65%;
    }
    div.search-box button {
        width: 10%;
    }
    span.icon {
        height: 35px;
        width: 35px;
    }
}

/* Sidebar */
aside.sidebar {
    position: fixed;
    display: block;
    z-index: 15;
    height: 115vh;
    width: 100%;
    background-color: #fff;
    top: 0px;
    left: 0px;
    margin-left: -100%;
    transition: 0.2s ease;
    z-index: 10;
}
aside.sidebar.active {
    margin-left: 0%;
    transition: 0.2s ease;
}
aside.sidebar a.sidebar-tab {
    position: relative;
    padding: 10px 20px;
    color: #777;
    text-decoration: none;
    line-height: 1.5;
    background-color: transparent;
    font-weight: 500;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-bottom: 5px;
}
aside.sidebar a.sidebar-tab.active {
    color: var(--ascent);
    background-color: rgba(0, 0, 0, 0.03);
}
aside.sidebar a.sidebar-tab::after {
    content: " ";
    position: absolute;
    height: 0%;
    width: 5px;
    background-color: var(--ascent);
    top: 50%;
    left: 0%;
    -webkit-transform: translate(-0%,-50%);
            transform: translate(-0%,-50%);
    border-radius: 2px;
    transition: 0.1s ease;
}
aside.sidebar a.sidebar-tab.active::after {
    height: 60%;
    transition: 0.3s ease;
}
aside.sidebar a.sidebar-tab span.tab-icon {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
}
aside.sidebar p.user-email {
    font-size: 0.8rem;
    font-weight: normal;
    color: #999;
}
aside.sidebar img.user-img {
    margin: 10px;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    padding: 3px;
    border: 1px solid #999;
}
aside.sidebar span.sidebar-icon {
    padding: 5px;
    margin-top: -0px;
    margin-right: 10px;
}


li.category-dropdown {
    position: relative;
    z-index: 10;
}
li.category-dropdown div.category-menu {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 10;
}
nav.side-nav a.side-tab {
    z-index: 2;
    padding: 12.2px 25px;
    width: 100%;
    text-align: left;
    border: none;
    border-bottom: 1px solid #eee;
    border-radius: 0px;
    background-color: #fff;
    transition: 0.2s ease;
    color: #000;
}
nav.side-nav a.side-tab:last-child {
    border: none;
}
nav.side-nav a.side-tab:hover {
    z-index: 3;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: 0.2s ease;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    background-color: var(--ascent);
    color: #fff;
    border-radius: 3px;
}
