@import "../../App.css";

div.product-carousel {
    height: 70%;
    width: 100%;
    float: right;
    margin-right: 20px;
}
div.product-carousel img {
    outline: none !important;
}
@media (max-width: 767px) {
    div.product-carousel {
        margin: 0px;
        float: none;
        margin-bottom: 20px;
        width: 100%;
    }
}
div.product-carousel-preview {
    padding-right: 3px;
    border: none;
    margin: 1px;
    margin-bottom: 3px;
    border-radius: 5px;
}
div.product-preview-div {
    padding: 50px;
    overflow: hidden;
}
div.product-preview-div:hover img {
    transform: scale(1.5);
}
div.product-thumbnail-div {
    padding: 10px;
}
div.product-carousel-thumbnaliNav {
    padding-right: 5px;
}
div.product-carousel-thumbnaliNav img.product-carousel-thumbnail {
    cursor: pointer;
    border: 1.7px solid #ccc;
    opacity: 0.5;
    border-radius: 3px;
    outline: none;
}
div.product-carousel-thumbnaliNav .slick-current img {
    opacity: 1;
    cursor: default;
    outline: none;
}

article.content figure {
    margin-bottom: 25px;
    padding: 0px;
}
article.content figure * {
    margin-bottom: 0px;
}
article.content figure h6 {
    color: #222;
    font-size: 1rem;
}
article.content figure h6 a {
    color: #222;
    font-size: 1rem;
}
h3.product-name {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}
div.rating-stars {
    display: flex;
    justify-content: baseline;
}
div.rating-stars span.active {
    color: orange;
}
div.rating-stars span.inactive {
    color: #ccc;
}

h6.title {
    font-weight: 600;
    color: #333;
    font-family: 'Poppins',sans-serif;
}
figure.pricing {
    margin: 10px 0px;
    display: flex;
    justify-content: start;
    align-items: baseline;
}
figure.pricing h2,
figure.pricing h5 {
    margin-right: 15px;
    font-family: 'Poppins', sans-serif;
}
figure.pricing h2.discounted-price {
    font-weight: bold;
    color: #000;
}
figure.pricing h5.actual-price {
    font-weight: bold;
    color: #999;
    text-decoration: line-through;
}
figure.pricing h5.discount-percentage {
    color: green;
    font-weight: bold;
}
figure.category a {
    margin-right: 5px;
    font-size: 1.1rem;
}
figure.highlight ul.highlight-list li {
    font-size: 0.9rem;
    margin-bottom: 7px;
}
figure.highlight ul.highlight-list {
    padding-left: 17px;
    padding-top: 20px;
    padding-bottom: 5px;
}
figure.size ul.size-list li {
    display: inline-block;
    margin-right: 7px;
    margin-top: 10px;
}
figure.size ul.size-list li button.size-tab {
    height: 40px;
    min-width: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border: none;
    border-radius: 7px;
    background-color: var(--ascent-transparent);
    color: #000;
}
figure.size ul.size-list li button.size-tab:focus {
    background-color: #000;
    color: #fff;
}
figure.size ul.size-list li button.size-tab.active {
    background-color: #000;
    color: #fff;
}
figure.tags a.tag {
    margin-right: 7px;
    font-size: 0.9rem;
    border: 1.5px solid var(--ascent);
    padding: 5px 10px;
    color: var(--ascent);
    border-radius: 2em;
    text-decoration: none !important;
    font-weight: 500;
    width: fit-content;
    display: inline-block;
    margin-top: 7px;
}