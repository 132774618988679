@import "../../App.css";

div.product-card-div {
    padding: 10px;
}
div.product-card-div a {
    text-decoration: none;
    color: #000;
}
figure.product-card {
    position: relative;
    margin: 0px;
    border-radius: 10px;
    transition: 0.2s ease;
    background: inherit;
    z-index: 2;
}
figure.product-card div.card-body {
    border-radius: 11px;
}
figure.product-card:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.2s ease;
    background: inherit;
}
figure.product-card:hover h6 {
    color: var(--ascent);
}
figure.product-card img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
figure.product-card h6 {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
}
figure.product-card h5.price {
    margin-top: 12px;
    margin-bottom: 0px;
    display: flex;
    justify-content: start;
    align-items: baseline;
}
figure.product-card h5.price span.new {
    font-family: 'Inter', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
}
figure.product-card h5.price span.old {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    text-decoration: line-through;
    color: #666;
    margin-left: 10px;
}
figure.product-card div.rating-stars {
    display: flex;
    justify-content: start;
    align-items: center;
}
figure.product-card div.rating-stars span {
    display: inline-block;
    margin-right: 2px;
}
figure.product-card div.rating-stars span.active {
    color: orange;
}
figure.product-card div.rating-stars span.inactive {
    color: #ccc;
}
figure.product-card div.row {
    display: flex;
}
figure.product-card div.row div {
    position: relative;
}
figure.product-card button.add-to-wishlist {
    text-decoration: none;
    color: #999;
    position: absolute;
    bottom: 5px;
    right: 20px;
    transition: 0.2s ease;
    font-size: 0.8rem;
    border: none;
    background: transparent;
    z-index: 3;
    background: inherit;
}
figure.product-card button.add-to-wishlist svg {
    margin-left: 3px;
    height: 35px;
    width: 35px;
}
figure.product-card button.add-to-wishlist:hover {
    color: #ff0a54;
    transition: 0.2s ease;
}

@media (max-width: 767px) {
    div.product-card-div {
        padding: 10px 0px;
    }
    figure.product-card h6 {
        font-size: 0.8rem;
    }
    figure.product-card div.card-body {
        padding: 12px 10px;
    }
    figure.product-card h5.price span.new {
        font-size: 0.95rem;
    }
    figure.product-card h5.price span.old {
        font-size: 0.8rem;
        margin-left: 5px;
    }
    figure.product-card button.add-to-wishlist {
        bottom: 0px;
        right: 13px;
    }
    figure.product-card button.add-to-wishlist svg {
        margin-left: 0px;
        height: 23px;
        width: 23px;
    }
}