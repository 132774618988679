@import "../../App.css";

/* Header  */
header.header {
    height: auto;
    width: 100%;
    position: static;
    z-index: 10;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    background-color: #fff;
}
#desktop-header {
    display: block;
}
#mobile-header {
    display: none;
    position: fixed;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}
header.header nav {
    margin: 0px;
    padding: 0px;
}
header.header nav#top-nav { 
    padding: 20px 0px;
    background-color: var(--compliment);
}
header.header nav#bottom-nav { 
    padding: 0px 0px;
    background-color: var(--ascent);
}
@media (max-width: 1000px) {
    header.header {
        border-bottom: 1px solid #ccc;
    }
    #desktop-header {
        display: none;
    }
    #mobile-header {
        display: block;
    }
}

/* Dropdown */
.dropdown {
    position: relative;
    z-index: 5;
}
.dropdown-menu {
    position: absolute;
    z-index: 3;
}
.dropdown:hover .dropdown-menu {
    display: block;
}


span.logo {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: normal;
    color: var(--ascent);
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}
li.nav-item {
    position: relative;
    margin: 0px 10px;
}
a.nav-link {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    margin: 0px;
    padding: 0px;
    font-size: 0.9rem;
    font-weight: 500;
}
header.header nav#top-nav a.nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}
header.header nav#top-nav a.nav-link div.nav-link-caption {
    margin-left: 10px;
    text-align: left;
    padding: 0px !important;
    margin-top: -2px;
}
header.header nav#top-nav a.nav-link div.nav-link-caption label {
    font-size: 0.85rem;
    cursor: pointer;
    color: #000;
    font-family: 'Poppins',sans-serif;
    margin-bottom: 1px;
    white-space: nowrap;
}
header.header nav#top-nav a.nav-link div.nav-link-caption hr {
    margin: 1px auto;
    padding: 0px 7px;
}
header.header nav#top-nav a.nav-link div.nav-link-caption .card-link {
    margin: 0px !important;
    font-size: 0.7rem;
    display: inline-block;
    white-space: nowrap;
}
header.header nav#bottom-nav a.nav-link {
    color: #fff;
    padding: 20px 10px;
    position: relative;
}
header.header nav#bottom-nav a.nav-link:hover {
    color: #fff;
}
header.header nav#bottom-nav a.nav-link:not(.ignore):after {
    content: " ";
    position: absolute;
    height: 5px;
    width: 0%;
    top: 100%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
header.header nav#bottom-nav a.nav-link:hover::after {
    width: 100%;
}
header.header nav#bottom-nav a.nav-link.active::after {
    width: 100%;
}
span.nav-icon {
    margin-top: -2px;
}
span.icon {
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #555;
    border: 1.7px solid #555;
}
span.icon:hover {
    color: #fff;
    border-color: var(--ascent);
    background-color: var(--ascent);
}
span.icon span.icon-badge {
    position: absolute;
    height: 20px;
    width: 20px;
    top: -5px;
    right: -5px;
    border-radius: 10px;
    font-size: 0.6rem;
    background-color: var(--ascent);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}
div.search-box select {
    width: 25%;
}
div.search-box input {
    width: 65%;
}
div.search-box button {
    width: 10%;
}
@media (max-width: 767px) {
    div.search-box select {
        width: 0%;
    }
    div.search-box input {
        width: 65%;
    }
    div.search-box button {
        width: 10%;
    }
    span.icon {
        height: 35px;
        width: 35px;
    }
}

/* Sidebar */
aside.sidebar {
    position: fixed;
    display: block;
    z-index: 15;
    height: 115vh;
    width: 100%;
    background-color: #fff;
    top: 0px;
    left: 0px;
    margin-left: -100%;
    transition: 0.2s ease;
    z-index: 10;
}
aside.sidebar.active {
    margin-left: 0%;
    transition: 0.2s ease;
}
aside.sidebar a.sidebar-tab {
    position: relative;
    padding: 10px 20px;
    color: #777;
    text-decoration: none;
    line-height: 1.5;
    background-color: transparent;
    font-weight: 500;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-bottom: 5px;
}
aside.sidebar a.sidebar-tab.active {
    color: var(--ascent);
    background-color: rgba(0, 0, 0, 0.03);
}
aside.sidebar a.sidebar-tab::after {
    content: " ";
    position: absolute;
    height: 0%;
    width: 5px;
    background-color: var(--ascent);
    top: 50%;
    left: 0%;
    transform: translate(-0%,-50%);
    border-radius: 2px;
    transition: 0.1s ease;
}
aside.sidebar a.sidebar-tab.active::after {
    height: 60%;
    transition: 0.3s ease;
}
aside.sidebar a.sidebar-tab span.tab-icon {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
}
aside.sidebar p.user-email {
    font-size: 0.8rem;
    font-weight: normal;
    color: #999;
}
aside.sidebar img.user-img {
    margin: 10px;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    padding: 3px;
    border: 1px solid #999;
}
aside.sidebar span.sidebar-icon {
    padding: 5px;
    margin-top: -0px;
    margin-right: 10px;
}


li.category-dropdown {
    position: relative;
    z-index: 10;
}
li.category-dropdown div.category-menu {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 10;
}
nav.side-nav a.side-tab {
    z-index: 2;
    padding: 12.2px 25px;
    width: 100%;
    text-align: left;
    border: none;
    border-bottom: 1px solid #eee;
    border-radius: 0px;
    background-color: #fff;
    transition: 0.2s ease;
    color: #000;
}
nav.side-nav a.side-tab:last-child {
    border: none;
}
nav.side-nav a.side-tab:hover {
    z-index: 3;
    transform: scale(1.1);
    transition: 0.2s ease;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    background-color: var(--ascent);
    color: #fff;
    border-radius: 3px;
}