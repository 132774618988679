@import "../../App.css";

figure.filter-card div.card-header,
figure.filter-card div.card-body,
figure.filter-card div.card-footer {
    padding: 15px !important;
}
figure.filter-card div.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
figure.filter-card h6.filter-title {
    font-family: 'Inter', sans-serif !important;
    margin-bottom: 0px !important;
    font-weight: 500;
    user-select: none !important;
    color: var(--ascent);
}
figure.filter-card div.form-check label.form-check-label {
    font-size: 1rem;
    margin-left: 7px;
    margin-top: 3px;
}
figure.filter-card div.form-check input.form-check-input {
    height: 1.25em;
    width: 1.25em;
}
figure.filter-card.active div.card-body  {
    display: block;
    transition: 0.3s ease;
}
figure.filter-card.inactive div.card-body {
    display: none;
    opacity: 0;
    transition: 0.3s ease;
}
figure.filter-card.inactive div.card-header {
    border-bottom: none !important;
    border-radius: 0.37rem;
    transition: all .1s ease;
}
figure.filter-card span.filter-card-toggler {
    color: var(--ascent);
}
figure.filter-card.active span.filter-card-toggler  {
    transform: rotate(180deg);
    transition: 0.3s ease;
}
figure.filter-card.inactive span.filter-card-toggler{
    transform: rotate(0deg);
    transition: 0.3s ease;
}