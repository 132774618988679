@import "../../App.css";

/* Dashboard Tabs (Start) */
aside.dashboard-tabs {
    overflow-x: scroll;
}
aside.dashboard-tabs::scrollbar {
    display: none;
}
aside.dashboard-tabs::-webkit-scrollbar {
    display: none;
}
aside.dashboard-tabs ul.dashboard-tab-list {
    display: flex;
    flex-direction: column;
}
aside.dashboard-tabs ul.dashboard-tab-list li {
    white-space: nowrap;
}
aside.dashboard-tabs a.dashboard-tab {
    padding: 10px 15px;
    color: #777;
    text-decoration: none;
    line-height: 1.5;
    background-color: transparent;
    font-weight: 500;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    border-radius: 0.37rem;
    border: none;
    margin-bottom: 5px;
    position: relative;
}
aside.dashboard-tabs a.dashboard-tab.active {
    color: var(--ascent);
    background-color: var(--ascent-transparent);
}
aside.dashboard-tabs a.dashboard-tab::after {
    content: " ";
    position: absolute;
    height: 0%;
    width: 5px;
    background-color: var(--ascent);
    top: 50%;
    left: 0%;
    transform: translate(-0%,-50%);
    border-radius: 2px;
    transition: 0.1s ease;
}
aside.dashboard-tabs a.dashboard-tab.active::after {
    height: 60%;
    transition: 0.3s ease;
}
aside.dashboard-tabs a.dashboard-tab span.tab-icon {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
}
/* Dashboard Tabs (End) */

/* Dashboard Card (Start) */
div.dashboard-card div.card-body,
div.dashboard-card div.card-header,
div.dashboard-card div.card-footer  {
    padding: 25px;
    background-color: #fff;
}
div.dashboard-card div.card-header h4.title {
    font-size: 1.4rem;
    margin-bottom: 0px;
    font-family: 'Poppins',sans-serif;
    font-weight: bold;
    color: #333;
}
div.dashboard-card div.card-body div.card {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.125);
}
div.dashboard-card div.card-body div.card:last-child {
    border-bottom: none;
    border-bottom-left-radius: 5px !important;
}
div.dashboard-card div.card-body div.card div.card-body {
    padding: 25px;
    border-radius: 5px;
}
div.dashboard-card div.card-body h6.title {
    font-family: 'Poppins',sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}
div.dashboard-card div.card-body p.description {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 5px;
}
/* Dashboard Card (End) */

/* My Orders (Start) */
div.order-card {
    border-radius: 0px !important;
}
div.order-card div.actions {
    margin-top: 15px;
}
div.order-item-card div.card-body {
    padding-top: 0px !important;
    display: flex;
    justify-content: start;
}
div.order-item-card div.product-details {
    margin-left: 10px;
    padding-top: 10px;
}
div.order-item-card img {
    height: 100px;
    width: 100px;
}
/* My Orders (End) */

/* Media Queries */
@media (max-width: 767px) {
    aside.dashboard-tabs ul.dashboard-tab-list {
        display: flex;
        flex-direction: row;
    }
    div.dashboard-card div.card-body,
    div.dashboard-card div.card-header,
    div.dashboard-card div.card-footer {
        padding: 15px;
    }
    div.dashboard-card div.card-body div.card div.card-body {
        padding: 15px;
    }
    div.dashboard-card div.card-body h6.title {
        font-size: 1rem;
    }
    aside.dashboard-tabs a.dashboard-tab {
        padding: 10px 20px;
    }
    aside.dashboard-tabs a.dashboard-tab::after {
        content: none;
    }
    aside.dashboard-tabs a.dashboard-tab.active::after {
        content: none;
    }
}

