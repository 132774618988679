@import "../../App.css";

footer.footer {
    background-color: #1b1a1b;
}
footer.footer div.container.top {
    padding: 50px 20px;
}
footer.footer div.footer-card {
    background-color: transparent;
    border: none;
    border-radius: 0px !important;
    border-right: 1px solid #ccc;
}
footer.footer div.footer-card div.card-body {
    padding: 10px;
    display: inline-flex;
}
footer.footer div.footer-card span.footer-card-icon {
    color: var(--ascent-light);
    padding-right: 15px;
}
div.container.top div:last-child > div.footer-card {
    border-right: none !important;
}
footer.footer div.footer-card h6.title {
    color: #fff;
    margin-bottom: 10px;
}
footer.footer div.footer-card p.description {
    color: #eee;
    margin-bottom: 0px !important;
}
footer.footer div.container.bottom {
    padding: 70px 20px;
    padding-bottom: 50px !important;
}
footer.footer div.copyright-div {
    padding: 20px 0px;
    background-color: #000;
    color: #fff;
    text-align: center;
}
footer.footer div.copyright-div p {
    margin-bottom: 0px;
    font-weight: lighter;
    font-size: 0.9rem;
}
footer.footer ul.social-media li {
    display: inline-block;
    margin-right: 20px;
}
footer.footer li.footer-list-item {
    padding: 7px 0px;
}
footer.footer ul.list-group {
    margin-left: 17px;
}
footer.footer ul.list-group li.footer-list-item {
    list-style: circle;
}
footer.footer h6.footer-list-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 14px;
    width: fit-content;
    padding-bottom: 16px;
    position: relative;
}
footer.footer h6.footer-list-title:after {
    position: absolute;
    content: " ";
    height: 3px;
    width: 50px;
    background-color: var(--ascent-light);
    left: 0px;
    bottom: 0px;
    border-radius: 1.5px;
}
footer.footer a.footer-link {
    color: #ccc;
    text-decoration: none;
    font-size: 0.9rem;
    margin-left: 0px;
    transition: 0.2s ease;
    font-weight: 400;
}
footer.footer a.footer-link:hover {
    transition: 0.2s ease;
    color: #fff;
}
footer.footer p.description {
    color: #ccc;
    text-decoration: none;
    font-size: 0.9rem;
    line-height: 1.6;
}
footer.footer input {
    color: #fff;
}
@media (max-width: 767px) {
    footer.footer ul {
        margin-bottom: 35px;
    }
    footer.footer div.container.top {
        padding: 30px 20px;
        padding-bottom: 15px;
    }
    footer.footer div.container.bottom {
        padding: 30px 20px;
        padding-bottom: 10px !important;
    }
    footer.footer div.footer-card {
        border-right: none;
        border-bottom: 1px solid #999;
    }
    footer.footer div.footer-card div.card-body {
        padding: 20px 0px;
    }
    div.container.top div:last-child > div.footer-card {
        border-bottom: none !important;
    }
}