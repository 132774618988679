@import "../../App.css";

div.cart-items figure.product-card-cart {
    margin: 0px;
    margin-bottom: 25px;
}
div.cart-items figure.product-card-cart div.card-body,
div.cart-items figure.product-card-cart div.card-footer {
    display: flex;
    padding: 15px;
    background-color: #fff;
}
div.cart-items figure.product-card-cart div.card-footer button {
    width: fit-content;
}
div.cart-items figure.product-card-cart div.card-body div.details {
    padding: 15px;
}
figure.product-card-cart div.card-body img.product-img {
    height: 150px;
    width: 150px;
    border-radius: 5px;
    padding: 5px;
    margin: 2px;
}
figure.product-card-cart div.card-body h6.product-name {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-decoration: none;
    color: #000;
    font-weight: 500;
    width: fit-content !important;
}
figure.product-card-cart div.card-body h6.product-price-new {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-top: 10px;
    text-decoration: none;
    color: #000;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 0px;
}
figure.product-card-cart div.card-body h6.product-price-old {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    margin-top: 10px;
    text-decoration: none;
    color: #666;
    font-weight: normal;
    text-decoration: line-through;
}
figure.product-card-cart div.card-body p.description {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 5px;
}
figure.product-card-cart div.card-body div.size-button-div button.size-button {
    height: 28px;
    width: fit-content;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 0.8rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 0.37rem;
    background-color: var(--ascent-transparent);
    margin-right: 4px;
}
figure.product-card-cart div.card-body div.size-button-div button.size-button.active {
    background-color: var(--ascent);
    color: #fff;
}
figure.product-card-cart div.card-body div.quantity-button-div button.quantity-button {
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    border: 1px solid var(--ascent);
    border-radius: 0.37rem;
    background-color: var(--ascent);
    color: #fff;
    margin: 0px !important;
    line-height: 1;
    font-weight: bold;
}
figure.product-card-cart div.card-body div.quantity-button-div input {
    height: 28px;
    width: 40px;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 1rem;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    border: 1px solid #ccc;
    align-items: center;
    margin: 0px 5px;
    cursor: default;
}
table.order-summary td {
    padding: 13px 0px;
}
table.order-summary h6.heading {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0px;
}
table.order-summary h6.data {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #777;
    font-size: 1.1rem;
    margin-bottom: 0px;
}
table.order-summary h6.heading.total {
    font-size: 1.2rem;
}
@media (max-width: 767px) {
    div.cart-items figure.product-card-cart div.card-body,
    div.cart-items figure.product-card-cart div.card-footer {
        display: flex;
        padding: 7px;
        background-color: #fff;
    }
    div.cart-items figure.product-card-cart div.card-footer button {
        width: 50%;
    }
    div.cart-items figure.product-card-cart div.card-body div.details {
        padding: 7px;
        padding-left: 10px;
    }
    figure.product-card-cart div.card-body h6.product-name {
        font-size: 1rem;
        margin-bottom: 5px;
    }
    figure.product-card-cart div.card-body h6.product-price {
        font-size: 1rem;
        margin-bottom: 5px;
    }
    figure.product-card-cart div.card-body img.product-img {
        height: 125px;
        width: 125px;
    }
}
